// import { dark } from "@theme-ui/presets"
import "../styles/global.css"

export default {
  breakpoints: ["576px", "768px", "992px", "1200px"],
  useColorSchemeMediaQuery: true,
  colors: {
    text: "#202124",
    text2: "#202124",
    background: "#fff",
    primary: "#88A40B",
    secondary: "#F1A21F",
    lightSecondary: "#F89D0A",
    highlight: "#eef2f2",
    highlight2: "#C0CCC9",
    highlight3: "#ACD6D5",
    highlight4: "#F3F6F6",
    highlight5: "#ECEEED",
    highlight6: "#F3F6F6",
    accent: "#707070",
    accent2: "#707070",
    muted: "#919191",
    textHighlight: "#02938B",
    white: "#fff",
    footerColor: "#707070",
    modes: {
      dark: {
        text: "hsl(210, 50%, 96%)",
        text2: "#c7c7c7",
        accent: "#8c908ce8",
        accent2: "rgba(255, 255, 255, 0.68)",
        white: "#eeeeee",
        footerColor: "#053f5e",
        background: "#022c43",
        primary: "#ffd700",
        secondary: "#ffd700",
        lightSecondary: "#ffd700",
        highlight: "hsl(71, 82%, 35%)",
        highlight2: "#8aab25",
        highlight3: "#9db161",
        highlight4: "#8aab25",
        highlight5: "#9db161",
        highlight6: "#9db161",
        muted: "#115173",
        gray: "hsl(210, 50%, 60%)",
        textHighlight: "#02938B",
      },
    },
  },
  fonts: {
    body: "Junicode, Open Sans, sans-serif",
    heading: "Junicode, Alice, serif",
    monospace: "Menlo, monospace",
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  fontSizes: [
    "0.75rem",
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3.5rem",
    "4.5rem",
    "5.5rem",
    "6rem",
  ],
  space: ["0rem", "0.25rem", "0.5rem", "1rem", "2rem", "4rem", "6rem"],
  text: {
    default: {
      color: "text",
      fontSize: 2,
      lineHeight: "body",
    },
    secondary: {
      color: "accent",
      lineHeight: "body",
      fontSize: 2,
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
    heading: {
      lineHeight: "heading",
      fontWeight: "bold",
      fontSize: 2,
    },
  },
  layout: {
    container: {
      maxWidth: [null, "1600px"],
    },
  },
  images: {
    avatar: {
      width: "120px",
      height: "120px",
    },
  },
  buttons: {
    menu: {
      color: "primary",
      padding: 0,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontSize: 2,
    },
    a: {
      color: "primary",
      cursor: "pointer",
      textTransform: "uppercase",
    },
  },
}
